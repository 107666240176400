import React, { Fragment, useState } from 'react';
import { Textarea, Select, Button } from 'evergreen-ui';

const TodoDetailForm = ({ setDetails, id, todo }) => {
  // set the notes and the status
  const [formData, setFormData] = useState({
    notes: todo.notes,
    status: todo.status,
  });
  const { notes, status } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDetails({ notes, id, status });
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Select
          value={status}
          margin={5}
          name='status'
          onChange={(e) => handleChange(e)}
        >
          <option value='new'>New</option>
          <option value='in-progress'>In Progress</option>
          <option value='complete'>Complete</option>
        </Select>
        <Textarea
          name='notes'
          placeholder='Add notes...'
          value={notes}
          onChange={(e) => handleChange(e)}
          margin={5}
        />
        <Button value='submit' type='submit' margin={5}>
          Save
        </Button>
      </form>
    </Fragment>
  );
};

//    todo={todo} setFormData={setFormData} id={id}

export default TodoDetailForm;
