import React, { useState } from 'react';
import TodoInput from './TodoInput';
import styled from '@emotion/styled';

export default function TodoControls(props) {
  const { actions } = props;
  //
  const [inputText, setInputText] = useState('');
  const handleInput = (e) => setInputText(e.target.value);

  //
  const handleAddTodo = () => {
    if (inputText) {
      actions.addTodo(inputText);
      setInputText('');
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddTodo();
    }
  };

  return (
    <Layout>
      <TodoInput
        value={inputText}
        addTodo={handleAddTodo}
        onChange={handleInput}
        onKeyDown={handleKeyPress}
      />
      {/* <ControlBar>
        {items.length > 0 && !allTodosAreCompleted && (
          <SelectAllButton
            selected={someTodosAreCompleted}
            onClick={actions.completeAllTodos}
          />
        )}
        {hasCheckedItems && (
          <CleanupButton onClick={actions.clearCompletedTodos}>
            Clear Completed
          </CleanupButton>
        )}
      </ControlBar> */}
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
`;
