import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { useTodoItems } from './useTodoItems';
import { useStitchAuth } from './StitchAuth';
import styled from '@emotion/styled';
import TodoDetailForm from './TodoDetailForm';

export default function TodoDetails(props) {
  let { id } = useParams();
  const { currentUser } = useStitchAuth();
  const {
    actions: { getTodo, setDetails },
    todo,
  } = useTodoItems(currentUser.id);

  useEffect(() => {
    getTodo({ id });
  }, []);

  return (
    <Fragment>
      {todo === null ? (
        <Spinner />
      ) : (
        <div>
          <Title>Issue: {todo.issue}</Title>
          <ul>
            <li>What will you do to deal with the situation?</li>
            <li>How often is this occurring?</li>
            <li>Was the plan followed through with?</li>
          </ul>
          <TodoDetailForm setDetails={setDetails} id={id} todo={todo} />
        </div>
      )}
    </Fragment>
  );
}

const Title = styled.div`
  margin: 5;
`;
