import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ErrorBoundary from 'react-error-boundary';
import { Card, CardTitle } from 'reactstrap';
import { useStitchAuth } from './StitchAuth';
import TodoControls from './TodoControls';
import { useTodoItems } from './useTodoItems';
import TodoItem from './TodoItem';

export default function TodoList() {
  const { currentUser } = useStitchAuth();
  const todos = useTodoItems(currentUser.id);
  const { items } = todos;

  return (
    <Fragment>
      <Title>
        <h1>Your Pain Button List</h1>
        <TodoControls {...todos} />
        <List>
          {items.length === 0 && <div>No todos yet.</div>}
          {items.map((item) => (
            <TodoItem
              key={item._id.toString()}
              item={item}
              //   toggleStatus={() => actions.toggleTodoStatus(item._id)}
            />
          ))}
        </List>
      </Title>
    </Fragment>
  );
}

const Title = styled(CardTitle)`
  margin: 0;
  h1 {
    padding: 20px;
    margin: 0;
  }
`;
const List = styled.ul`
  padding: 0;
  margin-top: 10px;
  width: 450px;
`;
