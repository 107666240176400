import React, { useReducer } from 'react';
import { items } from '../stitch';
import { ObjectId } from 'mongodb';

const todoReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'setTodos': {
      return {
        ...state,
        todos: payload.todos || [],
      };
    }
    case 'addTodo': {
      const newTodo = {
        ...payload,
      };
      return {
        ...state,
        todos: [...state.todos, newTodo],
      };
    }
    case 'setTodo': {
      return {
        ...state,
        todo: payload,
      };
    }
    default: {
      console.error(`Received invalid todo action type: ${type}`);
    }
  }
};

const initialState = {
  todos: [],
  todo: null,
};

// ACTIONS
export function useTodoItems(userId) {
  //
  const [state, dispatch] = useReducer(todoReducer, initialState);

  // get the todos
  const loadTodos = async () => {
    const todos = await items.find({}, { limit: 1000 }).asArray();
    dispatch({
      type: 'setTodos',
      payload: { todos },
    });
  };

  // add a todo
  const addTodo = async (issue) => {
    const todo = { issue, owner_id: userId, status: 'new' };
    const result = await items.insertOne(todo);
    dispatch({ type: 'addTodo', payload: { ...todo, _id: result.insertedId } });
  };

  // get a single todo item
  const getTodo = async ({ id }) => {
    const todo = await items.find({ _id: ObjectId(id) }).toArray();
    dispatch({
      type: 'setTodo',
      payload: todo[0],
    });
  };

  // save the Details for a todo item
  const setDetails = async ({ notes, id, status }) => {
    console.log(notes + id + status);

    const update = {
      $set: {
        notes: notes,
        status: status,
      },
    };
    items
      .updateOne({ _id: ObjectId(id) }, update)
      .then((result) => {
        const { matchedCount, modifiedCount } = result;
        if (matchedCount && modifiedCount) {
          alert('Success!');
          console.log(`Successfully set the details.`);
        }
      })
      .catch((err) => console.error(`Failed to update the item: ${err}`));
  };

  React.useEffect(() => {
    loadTodos();
  }, []);

  return {
    items: state.todos,
    todo: state.todo,
    actions: {
      addTodo,
      getTodo,
      setDetails,
    },
  };
}
