import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

TodoItem.propTypes = {
  item: PropTypes.object,
  remove: PropTypes.func,
  setStatus: PropTypes.func,
  toggleStatus: PropTypes.func,
};
export default function TodoItem(props) {
  let history = useHistory();
  const { item } = props;

  // const Checkbox = item.checked ? CheckedIcon : UncheckedIcon;

  function handleClick(id) {
    history.push(`/todo/${id}`);
  }

  return (
    <Todo onClick={() => handleClick(item._id.toString())}>
      <Layout>
        <Text>{item.issue}</Text>
      </Layout>
    </Todo>
  );
}
const Todo = styled(Card)`
  margin: 4px auto;
  :first-of-type {
    margin-top: 0px;
  }
`;
const Layout = styled(CardBody)`
  display: flex;
  align-items: top;
  padding: 10px !important;
`;
const Text = styled.span`
  font-size: 18px;
  line-height: 24px;
  margin-left: 10px;
  max-width: calc(100% - 24px - 10px);
`;
