// React
import React from 'react';
import styled from '@emotion/styled';
// Components & Hooks
import TodoApp from './TodoApp';
import Login from './Login';
import { StitchAuthProvider, useStitchAuth } from './StitchAuth';
import { Button } from 'reactstrap';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

function App() {
  return (
    <StitchAuthProvider>
      <AppUI />
    </StitchAuthProvider>
  );
}

function AppUI() {
  const {
    isLoggedIn,
    actions: { handleLogout },
  } = useStitchAuth();

  function handleClick() {
    handleLogout();
  }

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Router>
      <Layout>
        <Navbar>
          {isLoggedIn && <Button onClick={handleClick}>Logout</Button>}
          <AppTitle>
            <Link to='/'>Pain Button</Link>
          </AppTitle>
        </Navbar>
        <Switch>
          <Route path='/login'>
            <Login />
          </Route>
          <PrivateRoute path='/'>
            <TodoApp />
          </PrivateRoute>
        </Switch>
      </Layout>
    </Router>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  * {
    font-family: sans-serif;
  }
`;
const Navbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 62px;
  padding: 10px;
  background: #5e9668;
`;
const AppTitle = styled.h2`
  margin-right: auto;
  text-decoration: none;
`;

export default App;
